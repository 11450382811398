import React, {Component} from 'react';
import AboutImage from "./components/about-img.component";
import AboutLabel from "./components/about-label.component";

import {aboutService} from '../../services/fake/fake.service';

class AboutPage extends Component {
    render() {

        const service = aboutService();

        return (
            <div>
                <AboutImage />
                <AboutLabel body_text={service.data.bodyText}/>
            </div>
        );
    }
}

export default AboutPage;