import React, { Component } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import Routes from "./Routes";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const browserHistory = createBrowserHistory();

export default class App extends Component {
  render() {
    const theme = createMuiTheme({
      palette: {
        primary: {
          // Purple and green play nicely together.
          main: "#d8d433"
        }
      },
      typography: {
        fontFamily: ["Raleway"].join(",")
      }
    });

    return (
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          <Routes />
        </Router>
      </ThemeProvider>
    );
  }
}
