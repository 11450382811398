
const dataJson = {
    "aboutPage": {
        "data": {
            "bodyText": "About Us: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        }
    }
}

export const aboutService = () => {
    return dataJson.aboutPage;
}