import React, { useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { withRouter } from "react-router-dom";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import "../../../assets/css/minimal.css";
import GroupIcon from "@material-ui/icons/Group";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { FaPenFancy } from "react-icons/fa";
import { FaElementor } from "react-icons/fa";
import { FaUserEdit } from "react-icons/fa";
import { FaRegNewspaper } from "react-icons/fa";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MicIcon from "@material-ui/icons/Mic";
import Collapse from "@material-ui/core/Collapse";
import PhotoSizeSelectActualIcon from "@material-ui/icons/PhotoSizeSelectActual";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import MicNoneIcon from "@material-ui/icons/MicNone";
import ChatIcon from "@material-ui/icons/Chat";
import { invokeApi } from "../../../bl_libs/ApiCalling";
import logo from "../../../assets/images/logo.png";
import SettingsIcon from "@material-ui/icons/Settings";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const drawerWidth = 260;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "&.MuiAppBar-colorPrimary": {
      backgroundColor: "primary",
    },
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  iconscolor: {
    color: "#601124",
  },
  dwr: {
    "&.MuiListItem-button:hover": {
      backgroundColor: "white",
    },
  },
  mnu: {
    cursor: "pointer",
    color: "white",
  },
  menuclr: {
    backgroundColor: "#e9e7e752",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
}));

function Appbar(props) {
  console.log(props);
  const classes = useStyles();
  const theme = useTheme();

  const [islogout, setlogout] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [menu_open, setMenuOpen] = React.useState(false);
  const [podcast_open, setPodcastOpen] = React.useState(false);
  const [menu, setmenu] = React.useState("Dashboard");

  const [dashboard, setDashboard] = React.useState("Dashboard");
  const [users, setusers] = React.useState("Users");
  const [secrtery, setSecrtery] = React.useState("secrtery");
  const [appointment, setAppointment] = React.useState("appointment");

  const [chat, setchat] = React.useState("chat");
  const [clinicName, setClinicName] = useState()
  const [blogs, setblogs] = React.useState("All blogs");
  const [blog_category, setBlogCategory] = React.useState("blog_category");
  const [blog_authors, setBlogAuthors] = React.useState("blog_author");
  const [podcast, setPodcast] = React.useState("podcast");
  const [podcast_category, setPodcastCategory] = React.useState(
    "podcast_category"
  );
  const [photos, setPhotos] = React.useState("photos");
  const [videos, setVideos] = React.useState("video");
  const [story, setStory] = React.useState("story");
  const [news, setNews] = React.useState("news");
  const [setting, setSetting] = React.useState("setting");

  const handleClose = () => {
    setlogout(false);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleUpdatePassword = () => {
    props.history.push("/postit/updatepassword");
  };
  const logout = () => {
    setlogout(true);
  };
  const serverlogout = () => {
    const token = localStorage.getItem("token");
    let requestObj = {
      path: "/api/admin/logout",
      method: "GET",
      headers: {
        "x-sh-auth": token,
      },
    };
    // invokeApi(requestObj).then((res) => {
    //   console.log(res, "RESPONSE");
    // });
    return true;
  };
  const getClinicName = async () => {
    let requestObj = {
      path: "/api/clinic/dashboard_state",
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      const stats = res.stats;
      console.log(stats, "xxxxxxxx")
      setClinicName(stats.clinic_name)
    });
  };
  useEffect(() => {
    getClinicName()
  }, []);
  const handleagreelogout = () => {
    setlogout(false);
    props.history.push("/login");
    // if (serverlogout()) {
    //   localStorage.clear();
    //   props.history.push("/login");
    // }
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      props.history.push("/login");
    }
    if (localStorage.getItem("menu")) {
      const menu_item = localStorage.getItem("menu");
      localStorage.removeItem("menu");
      props.history.push("/postit/" + menu_item.replace(/\s/g, ""));
    }
  });
  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon style={{ color: "white" }} />
            </IconButton>
            <h2
              style={{ color: "white", fontWeight: "bold", marginTop: "15px" }}
            >
              {clinicName}
            </h2>
            <div className="menu-account">
              <Menu
                menuButton={
                  <AccountCircleIcon
                    className={classes.mnu}
                  ></AccountCircleIcon>
                }
              >
                <MenuItem className={classes.menuclr}>Post it clinic</MenuItem>
                <MenuItem
                  className={classes.menuclr}
                  onClick={handleUpdatePassword}
                >
                  Change password
                </MenuItem>
                <MenuItem onClick={logout} className={classes.menuclr}>
                  Logout
                </MenuItem>
              </Menu>
              <Menu
                menuButton={
                  <ArrowDropDownIcon
                    className={classes.mnu}
                  ></ArrowDropDownIcon>
                }
              >
                <MenuItem className={classes.menuclr}>
                  <div>
                    <p style={{marginTop:"0",marginBottom:"5px"}}><strong>Post it clinic</strong></p>
                    <p style={{marginTop:"0",marginBottom:"0"}}>{clinicName}</p>
                  </div>
                </MenuItem>
                <MenuItem
                  className={classes.menuclr}
                  onClick={handleUpdatePassword}
                >
                  Change password
                </MenuItem>
                <MenuItem onClick={logout} className={classes.menuclr}>
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <img src={logo} className={classes.lg} />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem
              style={
                dashboard === menu
                  ? { backgroundColor: "#d8d433", color: "white" }
                  : {}
              }
              button
              key={"dashboard"}
              onClick={() => {
                setmenu("Dashboard");
                localStorage.setItem("menu", "Dashboard");

                setDashboard("Dashboard");
                props.history.push("/postit/dashboard");
              }}
            >
              <ListItemIcon
                style={
                  dashboard === menu ? { color: "white" } : { color: "#d8d433" }
                }
              >
                <DashboardIcon style={{ width: 22 }} />
              </ListItemIcon>
              <ListItemText primary={dashboard} className={classes.txt} />
            </ListItem>

            <ListItem
              style={
                chat === menu
                  ? { backgroundColor: "#d8d433", color: "white" }
                  : {}
              }
              button
              key={"Chat"}
              onClick={() => {
                setmenu("chat");
                localStorage.setItem("menu", "chat");

                setchat("chat");
                props.history.push("/postit/chat");
              }}
            >
              <ListItemIcon
                style={
                  chat === menu ? { color: "white" } : { color: "#d8d433" }
                }
              >
                <ChatIcon style={{ width: 22 }} />
              </ListItemIcon>
              <ListItemText primary="Chat" className={classes.txt} />
            </ListItem>
          </List>
          <ListItem
            style={
              appointment === menu
                ? { backgroundColor: "#d8d433", color: "white" }
                : {}
            }
            button
            key={"Appointments"}
            onClick={() => {
              setmenu("appointment");
              localStorage.setItem("menu", "appointment");
              setAppointment("appointment");
              props.history.push("/postit/appointment");
            }}
          >
            <ListItemIcon
              style={
                appointment === menu ? { color: "white" } : { color: "#d8d433" }
              }
            >
              <AccessTimeIcon style={{ width: 22 }} />
            </ListItemIcon>
            <ListItemText primary="Appointment" className={classes.txt} />
          </ListItem>

          <Divider />

          <List>
            {["Logout"].map((text, index) => (
              <ListItem button key={text}>
                <ListItemIcon>
                  {index % 2 === 0 ? <PowerSettingsNewIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} onClick={logout} />
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Dialog
          open={islogout}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{`Are you sure you want to logout?`}</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
            <Button onClick={handleagreelogout} color="primary">
              Logout
            </Button>
          </DialogActions>
        </Dialog>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />

          {props.children}
        </main>
      </div>
    </>
  );
}
export default withRouter(Appbar);
